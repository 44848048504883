import {
  Box,
  Heading,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Text,
} from "@chakra-ui/react";
import type { SiteEntityType } from "@yotta-vision/core/models/site";
import type { ListAlertsResult } from "@yotta-vision/functions/rest/sites/alerts";
import type { LifetimeResult } from "@yotta-vision/functions/rest/timestream/lifetime-site-metrics";
import { useApi } from "lib/api";
import * as React from "react";
import {
  formatEnergy,
  formatLocaleNumber,
  formatNumber,
} from "~lib/utils/number-utils";

interface SitePopoverProps extends React.PropsWithChildren {
  site: SiteEntityType;
}

export const SitePopover: React.FC<SitePopoverProps> = ({ site, children }) => {
  const { data: metricsData } = useApi<LifetimeResult>(
    `/timeseries/lifetime-site-metrics/solarleaf`,
  );

  const { data: alerts } = useApi<ListAlertsResult>(
    `/sites/${site.siteID}/alerts`,
  );

  const kpis = React.useMemo(() => {
    const siteMetrics = metricsData?.find(
      (data) => data.siteID === site.siteID,
    );

    if (!siteMetrics) {
      return [];
    }

    return [
      {
        label: "Produced",
        value: formatEnergy(siteMetrics.pv),
      },
      {
        label: "Consumed",
        value: formatEnergy(-siteMetrics.out),
      },
      {
        label: "Total savings",
        value: [
          formatLocaleNumber(-siteMetrics.out * 0.2, {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          null,
        ] as const,
      },
      {
        label: "CO₂ reduction",
        value: [formatNumber(-siteMetrics.out * 7.09e-4), "tons"] as const,
      },
    ];
  }, [metricsData, alerts]);

  return (
    <Popover placement="top" trigger="hover" offset={[0, 34.5]} isLazy>
      <PopoverTrigger>{children}</PopoverTrigger>
      <Portal>
        <PopoverContent border="none" width="100%" zIndex="popover" bg="bg">
          <PopoverArrow bg="bg" />
          <PopoverHeader border="none">
            <Box display="flex" alignItems="baseline">
              <Box
                bgColor={
                  (alerts?.data.alerts.filter((al) => !al.resolved).length ||
                    0) > 0
                    ? "yottaRedAlert"
                    : "yottaGreenAlert"
                }
                borderRadius="50%"
                width="14px"
                height="14px"
                marginRight="7px"
                alignSelf="center"
                transition="background-color 300ms"
              ></Box>
              <Heading fontSize="lg" color="text">
                {site.name}
              </Heading>
              <Text fontSize="xs" color="subtle-text" marginLeft="15px">
                {site.city}, {site.state} {site.zip}
              </Text>
            </Box>
          </PopoverHeader>
          <PopoverBody data-testid="popover-body">
            <Box gap="14px" display="flex" justifyContent="space-evenly">
              {kpis.map(({ value: [amount, unit], label }, index) => (
                <Box key={index} data-testid="kpi-info">
                  <Text
                    display="inline"
                    fontWeight="500"
                    fontSize="xs"
                    color="text"
                  >
                    {amount}
                  </Text>{" "}
                  {unit && (
                    <Text display="inline" fontSize="2xs" color="text">
                      {unit}
                    </Text>
                  )}
                  <Text fontSize="2xs" color="subtle-text">
                    {label}
                  </Text>
                </Box>
              ))}
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
